import React, { Component } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import Fab from "@mui/material/Fab";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import * as queries from "../appsync/queries";
import { pink } from "@mui/material/colors";

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //when the component mounts, get the user's information from cognito
    Auth.currentUserInfo().then((user) => {
      console.log("Hello: ", user.attributes.email);
      if (user.attributes.email == "info@strongbo.ie") {
        console.log("AN ADMIN IS IN THE BUILDING");
      }
      //after the user's information is retrieved, use it's email attribute to query the API for the embed URL
      API.graphql(
        graphqlOperation(queries.getQuicksightDashboard, {
          email: user.attributes.email,
        })
      )
        .then((response) => {
          //use the embed URL to embed the dashboard in the container provided
          // console.log("***", response.data.getQuicksightDashboard);
          embedDashboard({
            url: response.data.getQuicksightDashboard.url,
            container: document.getElementById("dashboardContainer"),
            scrolling: "no",
          });
          if (user.attributes.email == "info@strongbo.ie") {
            embedDashboard({
              url: response.data.getQuicksightDashboard.url1,
              container: document.getElementById("dashboardContainer"),
              scrolling: "no",
            });
          }
        })
        //handle errors. Currently the app only logs to the console.
        .catch((error) => console.log(error));
    });
  }

  render() {
    return (
      <div
        style={{
          height: window.innerHeight,
          width: "100%",
          backgroundColor: "#282c34",
        }}
      >
        <div
          id="dashboardContainer"
          style={{
            height: "100%",
            width: "100%",
            border: "20px solid black",
          }}
        />
      </div>
    );
  }
}

export default Dashboard;
