import React, { Component } from "react";
import { Auth } from "aws-amplify";
import {
  Bars,
  Nav,
  NavLink,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.handleLogOut = this.handleLogOut.bind(this);
  }

  //handleLogOut signes the user out of the app
  handleLogOut() {
    Auth.signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <>
        <Nav
          style={{
            backgroundColor: "white",
            boxShadow: "0 0 8px 2px rgba(000, 000,000, 0.3)",
          }}
        >
          <NavLink to="/">
            <img
              style={{
                backgroundColor: "white",
                position: "absolute",
                left: 50,
                margin: 5,
                top: 5,
                width: "5rem",
              }}
              src={
                "https://static.wixstatic.com/media/7db617_2e3dff1ae56a4938aa7e1bbfcbb90bfa~mv2.png/v1/fill/w_95,h_67,al_c,q_85,usm_0.66_1.00_0.01/LOGO_White_edited.webp"
              }
            />
          </NavLink>
          <Bars />
          <NavMenu>
            <NavLink to="/">Console</NavLink>
            <NavLink to="/groups">Groups</NavLink>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to="/" onClick={this.handleLogOut}>
              Logout
            </NavBtnLink>
          </NavBtn>
        </Nav>
      </>
    );
  }
}
