import React, { Component, useRef, useEffect } from "react";
import { Outlet, Routes, Route } from "react-router-dom";
import Divider from "@mui/material";
import { I18n } from "aws-amplify";
import {
  Authenticator,
  useAuthenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  Button,
  CheckboxField,
  SelectField,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import Dashboard from "./routes/dashboard";
import { Auth } from "aws-amplify";
import Groups from "./routes/groups";
import Navbar from "./components/Navbar";
import IdleMonitor from "./components/IdleMonitor";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleLogOut() {
    Auth.signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Authenticator
        formFields={formFields}
        components={login_components}
        signUpAttributes={["name", "phone_number"]}
        loginMechanisms={["email"]}
      >
        <div className="App">
          <Navbar />
          {/* <Nav className="navigation" /> */}
          <Routes>
            {/* <Route path="/" element={<App />}> */}
            <Route path="/" element={<Dashboard />} />
            <Route path="groups" element={<Groups />} />
            {/* </Route> */}
          </Routes>
          <Outlet />
        </div>
        <IdleMonitor />
      </Authenticator>
    );
  }
}

I18n.putVocabulariesForLanguage("en", {
  "Sign In": "Login", // Tab header
  "Sign in": "Log in", // Button label
  "Sign in to your account": "Welcome Back!",
  Email: "Enter your email", // Username label
  Password: "Enter your password", // Password label
  "Forgot your password?": "Reset Password",
});

const login_components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="StrongBo logo"
          src="https://static.wixstatic.com/media/7db617_2e3dff1ae56a4938aa7e1bbfcbb90bfa~mv2.png/v1/fill/w_95,h_67,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO_White_edited.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />
          {/* Append & require Terms & Conditions field to sign up  */}
          <SelectField name="custom:country" label={"Country"}>
            <option value="Canada">Canada</option>
            <option value="Australia">Australia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Ireland">Ireland</option>
            <option value="Uk">Uk</option>
          </SelectField>
          <CheckboxField
            errorMessage={validationErrors.acknowledgement}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label="I agree to StrongBo's privacy policy"
          />
          <a
            href="https://www.strongbo-agtech.com/privacy-policy"
            target="_blank"
          >
            privacy policy
          </a>
        </>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`0 0 0 ${tokens.space.xl}`} level={3}>
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`0 0 0 ${tokens.space.xl}`} level={3}>
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          HELLO NURSE
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
};

const formFields = {
  // signIn: {
  //   email: {
  //     labelHidden: false,
  //     placeholder: "Enter your emailfff",
  //   },
  // },
  signUp: {
    name: {
      labelHidden: true,
      // label: "Full Name:",
      placeholder: "Enter your full name",
      isRequired: true,
      order: 15,
    },
    email: {
      labelHidden: true,
      placeholder: "Enter your email",
      order: 1,
    },
    phone_number: {
      dialCode: "+1",
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: "Enter your Password:",
      order: 15,
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: "Enter your Confirmation Code:",
      label: "New Label",
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: "Enter your Password Please:",
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      labelHidden: false,
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};
