export default {
  // apiGateway: {
  //   REGION: "eu-west-1",
  //   URL: process.env.REACT_APP_API_ENDPOINT
  // },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_H8GgNmYQu",
    APP_CLIENT_ID: "4aotclvosak6mra4aqaoa6565u",
    GRAPHQL_ENDPOINT:
      "https://jozbifmssvgcxmge3xqi5xn2a4.appsync-api.eu-west-1.amazonaws.com/graphql",
    // IDENTITY_POOL_ID: "eu-west-1:03ee7563-f33b-453b-be92-51cafd0c6179"
  },
};
