import LinearProgress from "@mui/material/LinearProgress";
import React from "react";

export default function LinearLoading(props) {
  if (props.loading) {
    return <LinearProgress />;
  } else {
    return;
  }
}
