import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { inputAlphaNumeric } from "./inputAlphaNumeric";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { API, Auth, graphqlOperation } from "aws-amplify";
import * as queries from "../appsync/queries";
import { inputNumeric } from "./inputNumeric";
import LinearLoading from "./LinearLoading";

const ageOptions = [
  "Calves",
  "Weanlings",
  "Yearlings",
  "Young Adults",
  "Mature Adults",
];

const sexOptions = ["Bulls", "Steers", "Heifers/Cows", "Mixed"];

const penTypeOptions = ["Feedlot", "Pasture"];

const weightKg = {
  "0-300 kg": "0,300",
  "100-400 kg": "100,400",
  "200-500 kg": "200,500",
  "300-600 kg": "300,600",
  "400-700 kg": "400,700",
  "500-800 kg": "500,800",
  "600-900 kg": "600,900",
  "700-1000 kg": "700,1000",
};

const breedOptions = [
  "Angus",
  "Black Angus",
  "Bos Indicus",
  "British, Euro Cross",
  "British, Bos Indicus Cross",
  "Charloais",
  "Composite",
  "Dairy",
  "Euro",
  "Euro, Bos Indicus Cross",
  "Gelbvieh",
  "Hereford",
  "Highlands",
  "Holstein",
  "Limousin",
  "Red Angus",
  "Simmental",
  "Texas Longhorn",
];

const penSizeOptions = ["<1 Acres", "<5 Acres", "5+ Acres"];

export default function CreateGroupForm(props) {
  // console.log("Props: ", props.group);
  const [open, setOpen] = React.useState(false);
  const [group, setGroup] = React.useState({});
  const [devices, setDevices] = React.useState({});
  const [errMessage, setError] = React.useState("");
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    console.log("Devices: ", props);
    var d = props.devices;
    var ob = {};
    d.forEach((d) => (ob[d.deviceName] = d.deviceId));
    console.log("D object: ", ob);
    setDevices(ob);
    setGroup({
      ...group,
      api: props.group.api,
      email: props.group.email,
      username: props.group.username,
      timesEdited: props.group.timesEdited,
      startDate: props.group.startDate,
      archived: false,
      deleted: false,
    });

    setOpen(true);
  };

  const handleClose = () => {
    console.log("handleClose");
    setOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    setSubmitAttempted(true);
    Auth.currentUserInfo().then((user) => {
      API.graphql(graphqlOperation(queries.createGroup, group))
        .then((response) => {
          console.log("Create Group Response: ", response);
          props.lift(response.data.createGroup.userGroups);
          setLoading(false);
          setOpen(false);
        })
        .catch((error) => {
          console.log("CREATE GROUP ERR: ", error);
          setError(
            "Oops, please check you have filled out every field below and try again."
          );
          setLoading(false);
        });
    });
  };

  const handleChange = (event) => {
    console.log("Event: ", event);
    var id = event.target.name;
    var value = event.target.value;

    if (event.target.name === "deviceName") {
      console.log("DeviceName Change: ", devices[event.target.value]);
      setGroup({
        ...group,
        deviceId: devices[event.target.value],
        [id]: value,
      });
    } else {
      setGroup({ ...group, [id]: value });
    }
    console.log("state group: ", group);
  };

  const handleChangeDate = (event) => {
    console.log("Event: ", event.format());
    var id = "startDate";
    var value = event.format();
    setGroup({ ...group, [id]: value });
    console.log("state group: ", group);
  };

  const handleError = (errMessage) => {
    if (errMessage != "") {
      return (
        <DialogTitle sx={{ mb: 0 }} color={"red"}>
          {errMessage}
        </DialogTitle>
      );
    }
  };

  const error = group.groupName;

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Create New Group
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a New Group</DialogTitle>
        {handleError(errMessage)}
        <LinearLoading loading={loading} />
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please fill out the following details to create a new group.
          </DialogContentText>
          <TextField
            sx={{ mb: 1 }}
            required
            error={submitAttempted ? (group.groupName ? false : true) : false}
            // helperText={group.groupName ? " " : "Please enter a value!"}
            autoFocus
            value={group.groupName ? group.groupName : ""}
            // margin="dense"
            id="name"
            name="groupName"
            label="Group Name"
            type="email"
            fullWidth
            variant="outlined"
            onChange={(e) =>
              inputAlphaNumeric(e, (value) => {
                setGroup({ ...group, groupName: value });
              })
            }
          />

          <InputLabel id="device-label">Device</InputLabel>
          <Select
            // autoWidth
            sx={{ mb: 1 }}
            required
            labelId="device-label"
            fullWidth
            id="deviceName"
            name="deviceName"
            error={submitAttempted ? (group.deviceName ? false : true) : false}
            value={group.deviceName ? group.deviceName : ""}
            label="Device"
            onChange={handleChange}
          >
            {Object.keys(devices).map((val, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <InputLabel id="breed-label">Group Start Date</InputLabel>
            <DateTimePicker
              // label="DateTime picker"
              value={group.startDate ? group.startDate : moment().valueOf()}
              onChange={handleChangeDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    submitAttempted ? (group.startDate ? false : true) : false
                  }
                />
              )}
            />
          </LocalizationProvider>

          <TextField
            sx={{ mb: 1, mt: 2 }}
            required
            // autoFocus
            // margin="dense"
            id="numberOfHead"
            error={
              submitAttempted ? (group.numberOfHead ? false : true) : false
            }
            name="numberOfHead"
            label="Number of Head"
            fullWidth
            variant="outlined"
            onChange={(e) =>
              inputNumeric(e, (value) => {
                setGroup({ ...group, numberOfHead: value });
              })
            }
          />

          <InputLabel id="breed-label">Breed</InputLabel>
          <Select
            // autoWidth
            sx={{ mb: 1 }}
            labelId="breed-label"
            fullWidth
            id="breed"
            name="breed"
            error={submitAttempted ? (group.breed ? false : true) : false}
            value={group.breed ? group.breed : ""}
            label="Breed"
            onChange={handleChange}
          >
            {breedOptions.map((val, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>

          <InputLabel id="sex-label">Sex</InputLabel>
          <Select
            // autoWidth
            sx={{ mb: 1 }}
            labelId="sex-label"
            fullWidth
            id="sex"
            name="sex"
            error={submitAttempted ? (group.sex ? false : true) : false}
            value={group.sex ? group.sex : ""}
            label="Sex"
            onChange={handleChange}
          >
            {sexOptions.map((val, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>

          <InputLabel id="age-label">Age</InputLabel>
          <Select
            // autoWidth
            sx={{ mb: 1 }}
            labelId="age-label"
            fullWidth
            id="age"
            name="age"
            error={submitAttempted ? (group.age ? false : true) : false}
            value={group.age ? group.age : ""}
            label="Age"
            onChange={handleChange}
          >
            {ageOptions.map((val, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>

          <InputLabel id="weightRange-label">Weight Range</InputLabel>
          <Select
            // autoWidth
            sx={{ mb: 1 }}
            labelId="weightRange-label"
            fullWidth
            id="weightRange"
            name="weightRange"
            error={submitAttempted ? (group.weightRange ? false : true) : false}
            value={group.weightRange ? group.weightRange : ""}
            label="Weight Range"
            onChange={handleChange}
          >
            {Object.keys(weightKg).map((val, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>

          <InputLabel id="penType-label">Pen Type</InputLabel>
          <Select
            // autoWidth
            sx={{ mb: 1 }}
            labelId="penType-label"
            fullWidth
            id="penType"
            name="penType"
            error={submitAttempted ? (group.penType ? false : true) : false}
            value={group.penType ? group.penType : ""}
            label="Pen Type"
            onChange={handleChange}
          >
            {penTypeOptions.map((val, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>

          <InputLabel id="penSize-label">Pen Size</InputLabel>
          <Select
            // autoWidth
            sx={{ mb: 1 }}
            labelId="penSize-label"
            fullWidth
            id="penSize"
            name="penSize"
            error={submitAttempted ? (group.penSize ? false : true) : false}
            value={group.penSize ? group.penSize : ""}
            label="Pen Size"
            onChange={handleChange}
          >
            {penSizeOptions.map((val, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
