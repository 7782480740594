/*
  this is the graphql query to get the quicksight url
*/

export const getQuicksightDashboard = `query getQuicksightDashboard($email: String!) {
  getQuicksightDashboard(email: $email) {
    url
    url1
  }
}
`;

export const getGroups = `query getUserGroups($username: String!, $api: String!, $email: String!) {
  getUserGroups(username: $username, api: $api, email: $email) {
    userGroups { 
      deviceId
      email
      username
      deviceName
      groupName
      startDate
      finishDate
      creationDate
      lastEditDate
      timesEdited
      archived
      deleted
      numberOfHead
      breed
      sex
      age
      weightRange
      penType
      penSize
    }
    userDevices {
      deviceId
      deviceName
      email
    }

  }
}
`;

export const createGroup = `mutation createGroup(
  $api: String!,
  $deviceId: String!,
  $email: String!,
  $username: String!,
  $deviceName: String!,
  $groupName: String!,
  $startDate: String!,
  $finishDate: String,
  $creationDate: String,
  $lastEditDate: String,
  $timesEdited: Int!,
  $archived: Boolean!,
  $deleted: Boolean!,
  $numberOfHead: Int!,
  $breed: String!,
  $sex: String!,
  $age: String!,
  $weightRange: String!,
  $penType: String!,
  $penSize: String!,
  ) {
    createGroup(
      api: $api
      deviceId: $deviceId
      email: $email
      username: $username
      deviceName: $deviceName
      groupName: $groupName
      startDate: $startDate
      finishDate: $finishDate
      creationDate: $creationDate
      lastEditDate: $lastEditDate
      timesEdited: $timesEdited 
      archived: $archived
      deleted: $deleted
      numberOfHead: $numberOfHead 
      breed: $breed
      sex: $sex 
      age: $age 
      weightRange: $weightRange
      penType: $penType 
      penSize: $penSize 
    ) { userGroups { 
      deviceId
      email
      username
      deviceName
      groupName
      startDate
      finishDate
      creationDate
      lastEditDate
      timesEdited
      archived
      deleted
      numberOfHead
      breed
      sex
      age
      weightRange
      penType
      penSize
    }}
}
`;
